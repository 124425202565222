@font-face {
  font-family: 'LuckiestGuy';
  src: local('LuckiestGuy'), url(../src/assets/fonts/LuckiestGuy-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../src/assets/fonts/Raleway-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../src/assets/fonts/Raleway-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../src/assets/fonts/Raleway-Bold.ttf) format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'LuckiestGuy', 'Arial', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: 'Voltaire', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 768px) {

  /* This styles the background of the scrollbar */
  ::-webkit-scrollbar {
    width: 0.5rem;
    /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: black;
    /* color of the tracking area */
  }

  /* This styles the draggable scrolling handle */
  ::-webkit-scrollbar-thumb {
    background: #FFA500;
    /* scrollbar handle color */
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #CC7722;
    /* scrollbar handle color on hover */
  }

}


input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {

  overscroll-behavior: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.App {
  font-family: 'Saiyan', monospace
}

html,
body {
  overscroll-behavior: none;
  height: 100%;
}

div[tabindex="0"] > p {
  display: none !important;
  
}
div[tabindex="0"] > div > h2 {
  display: none !important;
  
}
div[tabindex="0"]{
  color: black;
}