@keyframes my-animation {
  from {
    transform: translateX(25%);
  }

  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 600px) {
  @keyframes my-animation {
    from {
      transform: translateX(6%);
    }

    to {
      transform: translateX(-100%);
    }
  }
}


.initial-position {
  opacity: 0;
}



@keyframes slideInLeft {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.visible.slide-in-left {
  animation: slideInLeft 1s ease forwards;
}


@keyframes growAnimation {
  from {
    transform: scale(0.75);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.visible.grow {
  animation: growAnimation 0.5s ease forwards;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.visible.fade-in {
  animation: fadeIn 2s ease forwards;
}






@keyframes slideInRight {
  from {
    transform: translateX(50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.visible.slide-in-right {
  animation: slideInRight 0.5s ease forwards;
}

.visible.counter-library-exercises::after {
  font: 900 55px system-ui;
  font-family: "LuckiestGuy";
  content: "+" counter(count);
  animation: counter 3s linear 1;
  counter-reset: count 100;
  animation-fill-mode: forwards;
  color: #1c4595;
}

@keyframes counter {

  10% {
    counter-increment: count 100;
  }

  20% {
    counter-increment: count 200;
  }

  30% {
    counter-increment: count 300;
  }

  40% {
    counter-increment: count 400;
  }

  50% {
    counter-increment: count 500;
  }

  60% {
    counter-increment: count 600;
  }

  70% {
    counter-increment: count 700;
  }

  80% {
    counter-increment: count 800;
  }

  90% {
    counter-increment: count 900;
  }

  100% {
    counter-increment: count 1000;
  }
}


@keyframes t {
  from {
    background-size: 0 200%;
  }
}

@keyframes b {
  50% {
    background-position: 0 -100%, 0 0;
  }
}