.saiyan-aura {
    position: relative;
    width: 200px; /* Adjust based on your image */
    height: 300px; /* Adjust based on your image */
    margin: 50px auto;
  }
  
  .saiyan-aura img {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* The glowing aura effect */
  .saiyan-aura::before, .saiyan-aura::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(255, 255, 102, 0.7) 0%, rgba(255, 204, 0, 0.5) 70%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
    filter: blur(20px);
    animation: flicker 0.15s infinite alternate;
    pointer-events: none;
  }
  
  /* Adding a second layer for depth */
  .saiyan-aura::after {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 204, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%);
    filter: blur(30px);
    animation: flicker 0.2s infinite alternate-reverse;
  }
  
  /* Flicker animation */
  @keyframes flicker {
    0% {
      transform: scale(1);
      opacity: 0.9;
    }
    100% {
      transform: scale(1.05);
      opacity: 0.7;
    }
  }

